/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/style.scss"

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log('in onRouteUpdate');
  console.log("new pathname", location.pathname);
  console.log("old pathname", prevLocation ? prevLocation.pathname : null);
  //console.log('activatig google ads');
  //var adsbygoogle;
  //(adsbygoogle = window.adsbygoogle || []).push({});
  //console.log({ adsbygoogle });
}

export const onInitialClientRender = () => {
  window.scrollTo(0, 0);
};

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const { pathname, search, hash } = location;

  if ((pathname.startsWith("/recipes/view/") || pathname === "/") && search === "" && hash === "") {
 
    setTimeout(function () {
      //  = window.pageYOffset || document.documentElement.scrollTop;
      //console.log('top before2='+top)
      window.scrollTo(0, 0);
      //top  = window.pageYOffset || document.documentElement.scrollTop;
      //console.log('top after2='+top)
    }, 100);
    
    return false;
  }
};

export { default as wrapRootElement } from "./src/redux-wrapper";
