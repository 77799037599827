import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux-store";
import { PersistGate } from "redux-persist/integration/react";

const ReduxWrapper = ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {element}
    </PersistGate>
  </Provider>
);

export default ReduxWrapper;
