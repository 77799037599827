const initialState = {
  userData: {
    accessToken: "",
    refreshToken: "",
    accessTokenExpires: 0,
    refreshTokenExpires: 0,
    userId: 0,
    userName: "",
    userDisplayName: "",
    userRoles: "",
    isVerified: false,
    rememberMe: false,
    captchaRequestToken: "",
    doShowSignIn: false,
    lastUpdate: Date.now(),
  },
};

const reducer = (state = initialState, action) => {
  //console('in reducer')
  //console('state='+state)
  //console('action.type='+action.type)
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        userData: {
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          accessTokenExpires: action.payload.accessTokenExpires,
          refreshTokenExpires: action.payload.refreshTokenExpires,
          userId: action.payload.userId,
          userName: action.payload.userName,
          userDisplayName: action.payload.userDisplayName,
          userRoles: action.payload.userRoles,
          isVerified: action.payload.isVerified,
          rememberMe: action.payload.rememberMe,
          captchaRequestToken: action.payload.captchaRequestToken,
          doShowSignIn: action.payload.doShowSignIn,
          lastUpdate: Date.now(),
        },
      };
    case "UPDATE_USER":
      return {
        ...state,
        userData: {
          ...state.userData,
          userName: action.payload.userName,
          userDisplayName: action.payload.userDisplayName,
          isVerified: action.payload.isVerified,
          lastUpdate: Date.now(),
        },
      };
    case "UPDATE_USER_PERSONAL_INFO":
      return {
        ...state,
        userData: {
          ...state.userData,
          userName: action.payload.userName,
          isVerified: action.payload.isVerified,
          lastUpdate: Date.now(),
        },
      };
    case "UPDATE_USER_PUBLIC_PROFILE":
      return {
        ...state,
        userData: {
          ...state.userData,
          userDisplayName: action.payload.userDisplayName,
          lastUpdate: Date.now(),
        },
      };
    case "SET_TOKEN":
      return {
        ...state,
        userData: {
          ...state.userData,
          accessToken: action.payload.accessToken,
          accessTokenExpires: action.payload.accessTokenExpires,
          lastUpdate: Date.now(),
        },
      };
    case "RESET":
      return {
        userData: {
          accessToken: "",
          refreshToken: "",
          accessTokenExpires: 0,
          refreshTokenExpires: 0,
          userId: 0,
          userName: "",
          userDisplayName: "",
          userRoles: "",
          isVerified: false,
          rememberMe: false,
          captchaRequestToken: "",
          doShowSignIn: false,
          lastUpdate: Date.now(),
        },
      };
    case "REHYDRATE":
      return {
        ...action.payload,
      };
    case "SET_CAPTCHA_REQUEST_TOKEN":
      return {
        ...state,
        userData: {
          ...state.userData,
          captchaRequestToken: action.payload.captchaRequestToken,
          lastUpdate: Date.now(),
        },
      };
    case "SET_DO_SHOW_SIGN_IN":
      return {
        ...state,
        userData: {
          ...state.userData,
          doShowSignIn: action.payload.doShowSignIn,
          lastUpdate: Date.now(),
        },
      };
    default:
      return state;
  }
};
export default reducer;
