import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import Reducer from "./reducers/user-reducer";

export const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, Reducer);

//const storedState = getStoredState(persistConfig)

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
//export const stored_state = storedState
